.custom-tooltip-arrow {
  position: absolute;
  left: 10px;
  top: 43%;
  content: "";
  width: 15px;
  height: 15px;
  background: #000;
  transform: rotate(45deg);
  pointer-events: none;
}
