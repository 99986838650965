.h-90 {
  height: 90% !important;
}

.details-box {
  height: 21rem;
}

@media (min-width: 576px) {
  .details-box {
    height: 15rem;
  }
}

@media (min-width: 768px) {
  .details-box {
    height: 20rem;
  }
}

@media (min-width: 1200px) {
  .details-box {
    height: 15rem;
  }
}

.funnel-box {
  height: 20rem;
}

.aum-graph {
  height: 36.5rem;
}

.top-investor-graph {
  height: 25rem;
}

.documents {
  height: 25rem;
  overflow-y: auto;
}

.investor-summary {
  height: 25rem;
}

.earnnest-contact {
  height: 25rem;
}
.body-scrollable {
  height: 360px;
  overflow-y: auto;
}

.sticky-header thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}