//
// print.scss
//

// Used invoice page
@media print {
  .vertical-menu,
  .right-bar,
  .page-title-box,
  .navbar-header,
  .footer,
  .hide-on-print // className to hide element on print
  {
    display: none !important;
  }
  .card-body,
  .main-content,
  .right-bar,
  .page-content,
  body {
    padding: 0.1rem 0.5rem;
    margin: 0;
    background-color: white !important;
  }
  .print // className to show element only during print
  {
    display: flex !important;
  }
  .card {
    border: 0;
    box-shadow: none !important;
  }
  @page {
    margin: 0;
    padding: 40px 10px;
  }
  th,
  td {
    padding: 0.25rem !important;
    font-size: 0.75rem !important;
  }
}
