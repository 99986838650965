.timeline {
  border-left: 3px solid #727cf5;
  list-style: none;
  position: relative;
}

.timeline-event {
  position: relative;
  padding: 12px 0;
  cursor: pointer;
}

.timeline-event:not(:last-child) {
  border-bottom: 1px dashed #e8ebf1;
}

.timeline-dot {
  position: absolute;
  left: -8.5px;
  top: 16px;
  height: 14px;
  width: 14px;
  background: white;
  border: 2px solid #727cf5;
  border-radius: 50%;
  z-index: 10px;
}

.timeline-content {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}

.timeline-details {
  margin: 0 20px;
}

.user-info {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0;
}

.timestamp {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 2px;
  color: #6c757d;
}
